.swiper-pagination-bullet {
    background-color: #D9D9D9; /* Inactive state color */
    opacity: 1; /* Ensure bullets are fully visible */
    width:10px;
    height:10px;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #0D65B7; /* Active state color */
  }

/* Pagination Dots */
/* .slick-dots {
  bottom: -50px;
} */

.slick-dots li button:before {
  font-size: 14px;
  color: #EEA92B;
}

.slick-dots li.slick-active button:before {
  color: white;
}

.slick-dots li.slick-active button {
  color: #EEA92B;
}
.slick-dots li button:hover:before {
  color: #EEA92B; /* Yellow on hover as well */
}




.carousel .slick-center .card-container {
  transform: scale(1);
  transition: transform 0.5s ease;
}

.carousel .card-container {
  transition: transform 0.5s ease;
  transform: scale(0.8);
}

.card-container {

  background-color: #D2E8FC;
  height: 320px;
  border-radius: 1rem;

  padding:0rem 0.5rem;
  margin-bottom: 2rem;
}


.card{
  justify-content: center;
  align-items: center;
  padding:1rem;
}

@media (max-width: 1154px) {
  .card-container {
    /* padding: 25px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blanchedalmond; */
    /* background-color: green; */
    width: 10px;
    height: "auto";
    padding:5px;
  }

}


@media (max-width: 700px) {
  .card-container {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blanchedalmond; */
    width: 93%;
    height:"auto";
  }


}


